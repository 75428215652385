import gsap from "gsap";

class Cookie {
  constructor(main) {
    this.cookiepage = document.querySelector(".cookie");
    this.ok = document.querySelector(".cookieok");
    this.done = document.querySelector(".cookieDone");
    this.result = document.querySelector(".cookieResult");
    this.back = document.querySelector(".back");
    this.bye = document.querySelector(".cookieb");
    this.pan = document.querySelector(".pan");
    this.scorejs = document.querySelector(".scorejs");
    this.timer = document.querySelector(".timer");
    this.score = 0;
    this.scorejs.innerText = this.score;

    this.time = 14;

    this.pan.addEventListener("touchstart", this.createCookie.bind(this));
    this.ok.addEventListener(
      "click",
      function () {
        this.timerf = setInterval(this.countdown.bind(this), 1000);
      }.bind(this)
    );
  }

  createCookie(e) {
    e.preventDefault();

    if (
      e.currentTarget.src.includes("(1)") ||
      e.currentTarget.src.includes("(2)") ||
      e.currentTarget.src.includes("(3)")
    ) {
      return;
    }

    let cookie = document.createElement("img");
    cookie.className = "realcookie";
    cookie.src = `./cookie/cookie${Math.floor(
      Math.random() * (11 - 1 + 1) + 1
    )}(1).png`;
    cookie.style.position = "fixed";
    cookie.style.height = "5vh";
    cookie.style.width = "auto";
    cookie.style.top =
      (e.touches[0].pageY - 20 || e.touches[0].clientY - 20) + "px";
    cookie.style.left =
      (e.touches[0].pageX - 20 || e.touches[0].clientX - 20) + "px";
    this.cookiepage.appendChild(cookie);

    switch (cookie.getAttribute("src")) {
      case `./cookie/cookie1(1).png`:
        setTimeout(function (e) {
          cookie.src = "./cookie/cookie1(2).png";
        }, 3000);

        setTimeout(
          function (e) {
            cookie.src = "./cookie/cookie1(3).png";
          }.bind(this),
          7000
        );
        break;

      case `./cookie/cookie2(1).png`:
        setTimeout(function (e) {
          cookie.src = "./cookie/cookie2(2).png";
        }, 4000);

        setTimeout(
          function (e) {
            cookie.src = "./cookie/cookie2(3).png";
          }.bind(this),
          8000
        );
        break;

      case `./cookie/cookie3(1).png`:
        setTimeout(function (e) {
          cookie.src = "./cookie/cookie3(2).png";
        }, 3000);

        setTimeout(
          function (e) {
            cookie.src = "./cookie/cookie3(3).png";
          }.bind(this),
          7000
        );
        break;

      case `./cookie/cookie4(1).png`:
        setTimeout(function (e) {
          cookie.src = "./cookie/cookie4(2).png";
        }, 4500);

        setTimeout(
          function (e) {
            cookie.src = "./cookie/cookie4(3).png";
          }.bind(this),
          9000
        );
        break;

      case `./cookie/cookie5(1).png`:
        setTimeout(function (e) {
          cookie.src = "./cookie/cookie5(2).png";
        }, 3500);

        setTimeout(
          function (e) {
            cookie.src = "./cookie/cookie5(3).png";
          }.bind(this),
          6500
        );
        break;

      case `./cookie/cookie6(1).png`:
        setTimeout(function (e) {
          cookie.src = "./cookie/cookie6(2).png";
        }, 3000);

        setTimeout(
          function (e) {
            cookie.src = "./cookie/cookie6(3).png";
          }.bind(this),
          8000
        );
        break;

      case `./cookie/cookie7(1).png`:
        setTimeout(function (e) {
          cookie.src = "./cookie/cookie7(2).png";
        }, 5000);

        setTimeout(
          function (e) {
            cookie.src = "./cookie/cookie7(3).png";
          }.bind(this),
          10000
        );
        break;

      case `./cookie/cookie8(1).png`:
        setTimeout(function (e) {
          cookie.src = "./cookie/cookie8(2).png";
        }, 1000);

        setTimeout(
          function (e) {
            cookie.src = "./cookie/cookie8(3).png";
          }.bind(this),
          5000
        );
        break;

      case `./cookie/cookie9(1).png`:
        setTimeout(function (e) {
          cookie.src = "./cookie/cookie9(2).png";
        }, 3000);

        setTimeout(
          function (e) {
            cookie.src = "./cookie/cookie9(3).png";
          }.bind(this),
          7500
        );
        break;

      case `./cookie/cookie10(1).png`:
        setTimeout(function (e) {
          cookie.src = "./cookie/cookie10(2).png";
        }, 2000);

        setTimeout(
          function (e) {
            cookie.src = "./cookie/cookie10(3).png";
          }.bind(this),
          6000
        );
        break;

      case `./cookie/cookie11(1).png`:
        setTimeout(function (e) {
          cookie.src = "./cookie/cookie11(2).png";
        }, 2500);

        setTimeout(
          function (e) {
            cookie.src = "./cookie/cookie8(3).png";
          }.bind(this),
          5500
        );
    }

    cookie.addEventListener("touchstart", this.clickedCookie.bind(this));
  }

  clickedCookie(e) {
    e.preventDefault();

    if (e.currentTarget.src.includes("(1)")) {
      e.currentTarget.remove();
    } else if (e.currentTarget.src.includes("(2)")) {
      this.score++;
      this.scorejs.innerText = this.score;
      e.currentTarget.remove();
    } else if (e.currentTarget.src.includes("(3)")) {
      if (this.score > 0) {
        this.score--;
      }
      this.scorejs.innerText = this.score;
      e.currentTarget.remove();
    }
  }

  countdown() {
    if (this.time == 0) {
      this.cookiedone();
      this.timer.innerHTML = "00:00";
      clearTimeout(this.timerf);
    } else {
      this.timer.innerHTML =
        this.time >= 10 ? "00:" + this.time : "00:0" + this.time;
      this.time--;
    }
  }

  cookiedone() {
    this.done.style.display = "block";
    if (this.score >= 25) {
      this.result.innerHTML = `Wow! you baked ${this.score} cookies. Impressive work.`;
      this.bye.innerHTML = `I'm a professional baker, bye`;
    } else if (this.score >= 15 && this.score < 25) {
      this.result.innerHTML = `Good joob, you baked ${this.score} cookies.`;
      this.bye.innerHTML = `Thanks, bye`;
    } else if (this.score >= 5 && this.score < 15) {
      this.result.innerHTML = `You baked ${this.score} cookies.`;
      this.bye.innerHTML = `Bye`;
    } else if (this.score >= 1 && this.score < 5) {
      this.result.innerHTML = `You only baked ${this.score} cookies.`;
      this.bye.innerHTML = `yeah, bye`;
    } else if (this.score === 0) {
      this.result.innerHTML = `You baked 0 cookie?!`;
      this.bye.innerHTML = `I don't even like cookie, bye`;
    }
  }

  reset() {
    document.querySelectorAll(".realcookie").forEach(function (elem) {
      elem.remove();
    });
    clearTimeout(this.timerf);
    this.timer.innerHTML = "00:15";
    this.time = 14;
    this.done.style.display = "none";
    this.score = 0;
    this.scorejs.innerText = this.score;
  }
}

export { Cookie };
