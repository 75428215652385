import gsap from "gsap";

let volumeCallback = null;
let volumeInterval = null;
let audioContext;
class Candle {
  constructor(main) {
    const intro = document.querySelector(".candleIntro");
    const candlelight1 = document.querySelector(".candlelight1");
    const candlelight2 = document.querySelector(".candlelight2");
    const candlelight3 = document.querySelector(".candlelight3");
    const ok = document.querySelector(".candleok");

    const candleout1 = document.querySelector(".candleout1");
    const candleout2 = document.querySelector(".candleout2");
    const candleout3 = document.querySelector(".candleout3");

    const candlebody = document.querySelector(".candlebody");

    candleout1.load();
    candleout2.load();
    candleout3.load();

    candlelight1.style.display = "block";
    candlelight2.style.display = "block";
    candlelight3.style.display = "block";

    setTimeout(function (e) {
      if (navigator.mediaDevices) {
        navigator.mediaDevices
          .getUserMedia({ audio: true, video: false })
          .then(function (stream) {
            audioContext = new AudioContext();
            let analyser = audioContext.createAnalyser();
            let audioSource = audioContext.createMediaStreamSource(stream);

            analyser.fftSize = 1024;
            analyser.minDecibels = -127;
            analyser.maxDecibels = 0;
            analyser.smoothingTimeConstant = 0.4;
            audioSource.connect(analyser);
            const volumes = new Uint8Array(analyser.frequencyBinCount);

            volumeCallback = () => {
              analyser.getByteFrequencyData(volumes);
              let volumeSum = 0;
              for (const volume of volumes) volumeSum += volume;
              const averageVolume = volumeSum / volumes.length;
              // console.log(averageVolume);

              // Value range: 127 = analyser.maxDecibels - analyser.minDecibels;
              if (
                averageVolume >= 55 &&
                candlelight1.style.display === "block"
              ) {
                blowout();
              }
            };
          })
          .catch(function (err) {
            alert("Need mic access for the full experience.");
          });
      } else {
        alert("Your browser does not support microphone access.");
        // candlebody.addEventListener("click", function (e) {
        //   if (candlelight1.style.display === "block") {
        //     blowout();
        //   }
        // });
      }
    }, 500);

    ok.addEventListener("click", function () {
      if (volumeCallback !== null && volumeInterval === null)
        volumeInterval = setInterval(volumeCallback, 200);
    });

    function blowout() {
      candleout1.style.display = "block";
      candleout2.style.display = "block";
      candleout3.style.display = "block";

      candleout1.play();
      candleout2.play();
      candleout3.play();

      gsap.to(".candlelight1", { duration: 0, opacity: 0, scaleY: 0 });
      gsap.to(".candlelight2", { duration: 0, opacity: 0, scaleY: 0 });
      gsap.to(".candlelight3", { duration: 0, opacity: 0, scaleY: 0 });

      candlelight1.style.display = "none";
      candlelight2.style.display = "none";
      candlelight3.style.display = "none";

      setTimeout(
        function () {
          candlelight1.style.display = "block";
          candlelight2.style.display = "block";
          candlelight3.style.display = "block";

          gsap.to(".candlelight1", {
            duration: 1,
            opacity: 1,
            scaleY: 1,
            transformOrigin: "50% 100%",
          });
          gsap.to(".candlelight2", {
            duration: 1,
            opacity: 1,
            scaleY: 1,
            transformOrigin: "50% 100%",
          });
          gsap.to(".candlelight3", {
            duration: 1,
            opacity: 1,
            scaleY: 1,
            transformOrigin: "50% 100%",
          });
        }.bind(this),
        3000
      );
    }
  }

  again() {
    if (navigator.mediaDevices) {
      audioContext.resume();
    }
  }

  reset() {
    if (navigator.mediaDevices) {
      audioContext.suspend();
    }
    if (volumeInterval !== null) {
      clearInterval(volumeInterval);
      volumeInterval = null;
    }
  }
}

export { Candle };
