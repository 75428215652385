import "./style.css";
import gsap from "gsap";
import { New } from "./World/new.js";

const desktop = document.querySelector(".desktop");
const mobile = document.querySelector(".first");
const trans = document.querySelector(".transition");

if (window.innerWidth > 650) {
  const close = document.querySelector(".desktopclose");
  close.addEventListener("click", function (e) {
    window.close();
  });
  desktop.style.display = "block";
  mobile.style.display = "none";
  trans.style.display = "none";
} else if (window.innerWidth < 650) {
  desktop.style.display = "none";
  mobile.style.display = "block";

  function transition() {
    gsap.to(".transition li", {
      duration: 0.5,
      scaleY: 0,
      transformOrigin: "bottom center",
      stagger: 0.08,
      delay: 0.3,
    });
  }

  window.addEventListener("load", (event) => {
    const app = new New();
    window.app = app;
    setTimeout(() => {
      transition();
    }, 500);
  });
}
