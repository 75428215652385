import gsap from "gsap";
import { Candle } from "./candle.js";
import { Snowman } from "./snowman.js";
import { Mistletoe } from "./mistletoe.js";
import { Cookie } from "./cookie.js";
import { Present } from "./present.js";
import { Feast } from "./feast.js";
import { Tree } from "./tree.js";
import { Santa } from "./santa.js";

class New {
  constructor() {
    this.myAudio = document.querySelector(".music");
    this.isPlaying = false;

    this.musicbtn = document.querySelector(".musicbtn");

    this.musicbtn.addEventListener(
      "click",
      function () {
        this.isPlaying ? this.myAudio.pause() : this.myAudio.play();
        this.isPlaying ? (this.isPlaying = false) : (this.isPlaying = true);
      }.bind(this)
    );

    this.candleMenu = document.querySelector(".menu1");
    this.mistletoeMenu = document.querySelector(".menu2");
    this.snowmanMenu = document.querySelector(".menu3");
    this.cookieMenu = document.querySelector(".menu4");
    this.santaMenu = document.querySelector(".menu5");
    this.treeMenu = document.querySelector(".menu6");
    this.feastMenu = document.querySelector(".menu7");
    this.presentMenu = document.querySelector(".menu8");

    this.desktop = document.querySelector(".desktop");
    this.first = document.querySelector(".first");
    this.back = document.querySelectorAll(".back");
    this.intro = document.querySelectorAll(".intro");
    this.ok = document.querySelectorAll(".ok");
    this.no = document.querySelectorAll(".no");

    this.candle = document.querySelector(".candle");
    this.mistletoe = document.querySelector(".mistletoe");
    this.cookie = document.querySelector(".cookie");
    this.snowman = document.querySelector(".snowman");
    this.tree = document.querySelector(".tree");
    this.feast = document.querySelector(".feast");
    this.santa = document.querySelector(".santa");
    this.present = document.querySelector(".present");
    this.doneb = document.querySelectorAll(".doneb");

    // this.desktopshow();

    this.candlejs;
    this.mistletoejs;
    this.snowmanjs;
    this.cookiejs;
    this.santajs;
    this.presentjs;

    this.start = false;

    this.candleMenu.addEventListener("click", this.candlef.bind(this));
    this.mistletoeMenu.addEventListener("click", this.mistletoef.bind(this));
    this.snowmanMenu.addEventListener("click", this.snowmanf.bind(this));
    this.cookieMenu.addEventListener("click", this.cookief.bind(this));
    this.santaMenu.addEventListener("click", this.santaf.bind(this));
    this.treeMenu.addEventListener("click", this.treef.bind(this));
    this.feastMenu.addEventListener("click", this.feastf.bind(this));
    this.presentMenu.addEventListener("click", this.presentf.bind(this));

    for (let i = 0; i < this.back.length; i++) {
      this.back[i].addEventListener("click", this.backf.bind(this));
    }

    for (let i = 0; i < this.no.length; i++) {
      this.no[i].addEventListener("click", this.backf.bind(this));
    }

    for (let i = 0; i < this.doneb.length; i++) {
      this.doneb[i].addEventListener("click", this.backf.bind(this));
    }

    for (let i = 0; i < this.ok.length; i++) {
      this.ok[i].addEventListener(
        "click",
        function (e) {
          e.target.parentNode.parentNode.style.display = "none";
        }.bind(this)
      );
    }
  }

  candlef(e) {
    this.transition();
    gsap.to(this.candleMenu, { duration: 0.3, rotate: "+=360" });

    if (!this.candlejs) {
      this.candlejs = new Candle(this);
    } else {
      this.candlejs.again();
    }

    // this.candlejs.style.display = "none";
    if (this.isPlaying) {
      this.myAudio.pause();
    }

    setTimeout(
      function () {
        this.first.style.display = "none";
        this.candle.style.display = "block";
      }.bind(this),
      800
    );
  }

  mistletoef() {
    this.transition();
    gsap.to(this.mistletoeMenu, { duration: 0.3, rotate: "+=360" });

    if (!this.mistletoejs) {
      this.mistletoejs = new Mistletoe(this);
    }
    this.mistletoe = document.querySelector(".mistletoe");
    this.mistletoe.style.display = "none";

    setTimeout(
      function () {
        this.first.style.display = "none";
        this.mistletoe.style.display = "block";
      }.bind(this),
      800
    );
  }

  snowmanf() {
    this.transition();
    gsap.to(this.snowmanMenu, { duration: 0.3, rotate: "+=360" });

    if (!this.snowmanjs) {
      this.snowmanjs = new Snowman(this);
    } else {
      this.snowmanjs.restart();
    }

    this.snowman = document.querySelector(".snowman");
    this.snowman.style.display = "none";

    setTimeout(
      function () {
        this.first.style.display = "none";
        this.snowman.style.display = "block";
      }.bind(this),
      800
    );
  }

  cookief() {
    this.transition();
    gsap.to(this.cookieMenu, { duration: 0.3, rotate: "+=360" });

    if (!this.cookiejs) {
      this.cookiejs = new Cookie(this);
    }
    this.cookie.style.display = "none";

    setTimeout(
      function () {
        this.first.style.display = "none";
        this.cookie.style.display = "block";
      }.bind(this),
      800
    );
  }

  santaf() {
    this.transition();
    gsap.to(this.santaMenu, { duration: 0.3, rotate: "+=360" });

    if (!this.santajs) {
      this.santajs = new Santa(this);
    }

    setTimeout(
      function () {
        this.first.style.display = "none";
        this.santa.style.display = "block";
      }.bind(this),
      800
    );
  }

  treef() {
    this.transition();
    gsap.to(this.treeMenu, { duration: 0.3, rotate: "+=360" });

    if (!this.treejs) {
      this.treejs = new Tree(this);
    }
    this.tree.style.display = "none";

    setTimeout(
      function () {
        this.first.style.display = "none";
        this.tree.style.display = "block";
      }.bind(this),
      800
    );
  }

  feastf() {
    this.transition();
    gsap.to(this.feastMenu, { duration: 0.3, rotate: "+=360" });

    if (!this.feastjs) {
      this.feastjs = new Feast(this);
    }
    this.feast.style.display = "none";

    setTimeout(
      function () {
        this.first.style.display = "none";
        this.feast.style.display = "block";
      }.bind(this),
      800
    );
  }
  presentf() {
    this.transition();
    gsap.to(this.presentMenu, { duration: 0.3, rotate: "+=360" });

    setTimeout(
      function () {
        this.first.style.display = "none";
        this.present.style.display = "block";

        this.presentjs = new Present(this);
      }.bind(this),
      800
    );
  }

  backf() {
    this.transition();

    setTimeout(
      function () {
        var a = document.querySelectorAll(".menu");
        for (let i = 0; i < a.length; i++) {
          a[i].classList.remove("clicked");
        }

        for (let i = 0; i < this.intro.length; i++) {
          this.intro[i].style.display = "block";
        }

        this.first.style.display = "block";

        if (this.candle.style.display === "block") {
          this.candle.style.display = "none";
          this.candlejs.reset();
          if (this.isPlaying) {
            this.myAudio.play();
          }
        } else if (this.snowman.style.display === "block") {
          this.snowman.style.display = "none";
          this.snowmanjs.reset();
        } else if (this.cookie.style.display === "block") {
          this.cookie.style.display = "none";
          this.cookiejs.reset();
        } else if (this.santa.style.display === "block") {
          this.santa.style.display = "none";
          this.santajs.reset();
        } else if (this.mistletoe.style.display === "block") {
          this.mistletoe.style.display = "none";
          this.mistletoejs.reset();
        } else if (this.tree.style.display === "block") {
          this.tree.style.display = "none";
          this.treejs.reset();
        } else if (this.feast.style.display === "block") {
          this.feast.style.display = "none";
          this.feastjs.reset();
        } else if (this.present.style.display === "block") {
          this.present.style.display = "none";
          this.presentjs.reset();
        }
      }.bind(this),
      800
    );
  }

  transition() {
    var tl = gsap.timeline();
    tl.set(".transition li", {
      scaleY: 0,
      transformOrigin: "bottom center",
    });
    tl.to(".transition li", {
      duration: 0.5,
      scaleY: 1,
      stagger: 0.08,
      delay: 0.02,
    });
    tl.to(".transition li", {
      duration: 0.5,
      scaleY: 0,
      stagger: 0.08,
      delay: 0.3,
    });
  }
  preloadImage() {
    this.cookie1;
  }

  desktopshow() {
    if (window.innerWidth < 900) {
      this.desktop.style.display = "none";
      this.first.style.display = "block";
    } else {
      this.desktop.style.display = "block";
      this.first.style.display = "none";
    }
  }
}

export { New };
