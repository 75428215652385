import gsap from "gsap";

class Present {
  constructor() {
    this.presentPage = document.querySelector(".present");
    // this.presentsound = new Audio("./present/pop.mp3");
    this.presents = [];
    this.con = [];
    this.l = [];
    this.links = [
      "https://www.feedingamerica.org/",
      "https://www.map.org/",
      "https://www.directrelief.org/",
      "https://www.enterprisecommunity.org/",
      "https://m25m.org/",
      "https://www.wri.org/",
      "https://www.waterforsouthsudan.org/",
      "https://www.helpthehelpless.org/",
      "https://www.hopeforhaitischildren.org/",
      "https://www.ogt.org/",
      "https://www.rainforesttrust.org/",
      "https://www.achanceinlife.org/",
    ];

    this.count = 0;

    this.presentCreate();

    for (const present of this.presents) {
      present.addEventListener(
        "click",
        function (e) {
          present.remove();
          gsap.killTweensOf(present);

          let nl = document.createElement("a");
          nl.innerHTML = this.links[this.count];
          nl.href = this.links[this.count];
          nl.style.width = "150px";

          nl.style.position = "fixed";
          nl.style.zIndex = "-1";
          gsap.set(nl, {
            x:
              (e.pageX || e.clientX) - gsap.getProperty(nl, "width") / 2 + "px",
            y: (e.pageY || e.clientY) + "px",
          });
          this.presentPage.appendChild(nl);
          this.l.push(nl);

          this.confetti(e);

          this.count++;
        }.bind(this)
      );
    }
  }

  presentCreate() {
    let count = 1;
    for (let i = 0; i < 4; i++) {
      var im = new Image();
      im.className = "presentimg";
      im.style.position = "fixed";
      im.style.width = "12vw";
      im.style.height = "12vw";
      im.src = `./present/p${count}.png`;
      this.presents.push(im);
      count++;

      this.present1(im);
      this.presentPage.appendChild(im);
    }
    let countt = 5;

    for (let i = 0; i < 4; i++) {
      var im = new Image();
      im.className = "presentimg";
      im.style.position = "fixed";
      im.style.width = "12vw";
      im.style.height = "12vw";
      im.src = `./present/p${countt}.png`;
      this.presents.push(im);
      countt++;
      this.present2(im);
      this.presentPage.appendChild(im);
    }
    let counttt = 9;
    for (let i = 0; i < 4; i++) {
      var im = new Image();
      im.className = "presentimg";
      im.style.position = "fixed";
      im.style.width = "12vw";
      im.style.height = "12vw";
      im.src = `./present/p${counttt}.png`;
      this.presents.push(im);
      counttt++;
      this.present3(im);
      this.presentPage.appendChild(im);
    }
  }

  present1(im) {
    let tl1 = gsap.timeline({ repeat: -1, yoyo: true });
    let pos = {
      maxX:
        this.presentPage.offsetWidth -
        (this.presentPage.offsetWidth * gsap.getProperty(im, "width")) / 100,
      minX: 0,
      maxY:
        this.presentPage.offsetHeight -
        (this.presentPage.offsetWidth * gsap.getProperty(im, "width")) / 100,
      minY: 0,
    };

    let dur1 = this.random(3, 12);

    tl1.set(im, {
      x: pos.minX,
      y: (pos.maxY * this.random(1, 10)) / 10,
    });

    tl1.to(im, {
      duration: dur1,
      x: pos.maxX,
      y: (pos.maxY * this.random(1, 10)) / 10,
      ease: "none",
    });

    tl1.to(im, {
      duration: dur1,
      x: pos.minX,
      y: (pos.maxY * this.random(1, 10)) / 10,
      ease: "none",
    });

    tl1.to(im, {
      duration: dur1,
      x: pos.maxX,
      y: (pos.maxY * this.random(1, 10)) / 10,
      ease: "none",
    });

    tl1.to(im, {
      duration: dur1,
      x: pos.minX,
      y: (pos.maxY * this.random(1, 10)) / 10,
      ease: "none",
    });
  }

  present2(im) {
    let tl = gsap.timeline({ repeat: -1, yoyo: true });
    let pos = {
      maxX:
        this.presentPage.offsetWidth -
        (this.presentPage.offsetWidth * gsap.getProperty(im, "width")) / 100,
      minX: 0,
      maxY:
        this.presentPage.offsetHeight -
        (this.presentPage.offsetWidth * gsap.getProperty(im, "width")) / 100,
      minY: 0,
    };
    let dur = this.random(5, 14);

    tl.set(im, {
      x: (pos.maxX * this.random(1, 10)) / 10,
      y: pos.minY,
    });

    tl.to(im, {
      duration: dur,
      x: (pos.maxX * this.random(1, 10)) / 10,
      y: pos.maxY,
      ease: "none",
    });

    tl.to(im, {
      duration: dur,
      x: (pos.maxX * this.random(1, 10)) / 10,
      y: pos.minY,
      ease: "none",
    });

    tl.to(im, {
      duration: dur,
      x: (pos.maxX * this.random(1, 10)) / 10,
      y: pos.maxY,
      ease: "none",
    });

    tl.to(im, {
      duration: dur,
      x: (pos.maxX * this.random(1, 10)) / 10,
      y: pos.minY,
      ease: "none",
    });
  }

  present3(im) {
    let tl = gsap.timeline({ repeat: -1, yoyo: true });
    let pos = {
      maxX:
        this.presentPage.offsetWidth -
        (this.presentPage.offsetWidth * gsap.getProperty(im, "width")) / 100,
      minX: 0,
      maxY:
        this.presentPage.offsetHeight -
        (this.presentPage.offsetWidth * gsap.getProperty(im, "width")) / 100,
      minY: 0,
    };

    let dur1 = this.random(3, 12);

    tl.set(im, {
      x: pos.maxX,
      y: (pos.maxY * this.random(1, 10)) / 10,
    });

    tl.to(im, {
      duration: dur1,
      x: pos.minX,
      y: (pos.maxY * this.random(1, 10)) / 10,
      ease: "none",
    });

    tl.to(im, {
      duration: dur1,
      x: pos.maxX,
      y: (pos.maxY * this.random(1, 10)) / 10,
      ease: "none",
    });

    tl.to(im, {
      duration: dur1,
      x: pos.minX,
      y: (pos.maxY * this.random(1, 10)) / 10,
      ease: "none",
    });

    tl.to(im, {
      duration: dur1,
      x: pos.maxX,
      y: (pos.maxY * this.random(1, 10)) / 10,
      ease: "none",
    });
  }

  random(min, max) {
    return Math.floor(min + Math.random() * (max - min));
  }

  confetti(e) {
    for (var i = 0; i < 9; i++) {
      var c = document.createElement("c");
      c.style.position = "fixed";
      c.style.width = "1.1vw";
      c.style.height = "1.1vw";
      c.style.pointerEvents = "none";

      c.style.background =
        "#" + Math.floor(Math.random() * 16777215).toString(16);

      gsap.set(c, {
        x: (e.pageX || e.clientX) + "px",
        y: (e.pageY || e.clientY) + "px",
        opacity: 1,
      });

      gsap.to(c, {
        duration: 1,
        x:
          (e.pageX - this.random(-150, 150) ||
            e.clientX - this.random(-150, 150)) + "px",
        y:
          (e.pageY - this.random(-150, 150) ||
            e.clientY - this.random(-150, 150)) + "px",
        opacity: 0,
      });

      this.con.push(c);
      this.presentPage.append(c);
    }

    // setTimeout(
    //   function () {
    //     for (const ls of this.con) {
    //       ls.remove();

    //       console.log("removed");
    //     }
    //     this.con = [];
    //     console.log(this.con);
    //   }.bind(this),
    //   1200
    // );
  }

  reset() {
    for (const present of this.presents) {
      present.remove();
      gsap.killTweensOf(present);
    }

    for (const cons of this.con) {
      cons.remove();
      gsap.killTweensOf(cons);
    }

    for (const ls of this.l) {
      ls.remove();
    }

    this.presents = [];
    this.con = [];
    this.l = [];
    this.count = 0;
    // gsap.killTweensOf(this);
    // gsap.killTweensOf(tl2);
  }
}

export { Present };
