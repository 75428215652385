const brush = document.querySelector(".tree2");
const treePage = document.querySelector(".tree");
const leafbtn = document.querySelector(".leaf");
const ornbtn = document.querySelector(".ornament");
const starbtn = document.querySelector(".star");
const resetbtn = document.querySelector(".reset");
const draw = document.querySelector(".tree1");
let max = 5;
let min = 1;
const all = [];

class Tree {
  constructor() {
    this.isDrawing;

    resetbtn.addEventListener("click", function (e) {
      if (all.length > 0) {
        all.forEach((item) => item.remove());
        all.length = 0;
      }
    });
    leafbtn.addEventListener("click", function (e) {
      max = 5;
      min = 1;
    });

    ornbtn.addEventListener("click", function (e) {
      max = 15;
      min = 6;
    });
    starbtn.addEventListener("click", function (e) {
      max = 20;
      min = 16;
    });

    draw.addEventListener(
      "touchmove",
      function (e) {
        e.preventDefault();
        const clientX = e.touches[0].clientX || e.touches[0].pageX;
        const clientY = e.touches[0].clientY || e.touches[0].pageY;

        this.createLeaf(clientX, clientY);
      }.bind(this)
    );

    // draw.addEventListener(
    //   "mousemove",
    //   function (e) {
    //     e.preventDefault();
    //     if (!this.isDrawing) {
    //       return;
    //     }
    //     const clientX = e.clientX || e.pageX;
    //     const clientY = e.clientY || e.pageY;
    //     this.createLeaf(clientX, clientY);
    //   }.bind(this)
    // );
    // draw.addEventListener(
    //   "mouseup",
    //   function (e) {
    //     e.preventDefault();
    //     this.isDrawing = false;
    //   }.bind(this)
    // );
    // draw.addEventListener(
    //   "mousedown",
    //   function (e) {
    //     e.preventDefault();
    //     this.isDrawing = true;
    //   }.bind(this)
    // );
  }

  createLeaf(x, y) {
    let t = document.createElement("img");
    t.className = "tleaf";
    t.src = `./tree/t${Math.floor(Math.random() * (max - min) + min)}.png`;
    t.style.position = "fixed";
    t.style.height = "10%";
    t.style.width = "auto";
    t.style.top = (y - 20 || y - 20) + "px";
    t.style.left = (x - 20 || x - 20) + "px";
    brush.appendChild(t);
    all.push(t);
  }

  reset() {
    max = 5;
    min = 1;
    if (all.length > 0) {
      all.forEach((item) => item.remove());
      all.length = 0;
    }
    var tm = document.querySelectorAll(".tm");
    for (let i = 0; i < tm.length; i++) {
      tm[i].classList.remove("clickedt");
    }
    leafbtn.classList.add("clickedt");
  }
}

export { Tree };
