import gsap from "gsap";

class Mistletoe {
  constructor() {
    this.mis = document.querySelector(".mis");
    this.lips = document.querySelector(".lips");
    this.kiss1 = document.querySelector(".kiss1");
    this.kiss2 = document.querySelector(".kiss2");
    this.sound;

    this.mis.addEventListener("touchstart", this.misClick.bind(this));
    this.lips.addEventListener("touchstart", this.kissed.bind(this));
  }

  misClick(e) {
    e.preventDefault();
    this.kiss1.style.zIndex = "-1";
    this.kiss2.style.zIndex = "-2";

    // this.kiss2.currentTime = "0";
    // this.kiss1.currentTime = "0";
    this.kiss1.play();

    this.mis.style.pointerEvents = "none";

    setTimeout(
      function () {
        this.lips.style.pointerEvents = "auto";
      }.bind(this),
      700
    );
  }

  kissed(e) {
    e.preventDefault();
    this.kiss2.style.zIndex = "-1";
    this.kiss1.style.zIndex = "-2";
    // this.kiss2.load();
    // this.kiss2.pause();
    // this.kiss1.currentTime = "0";
    // this.kiss2.currentTime = "0";
    this.kiss2.play();
    this.lips.style.pointerEvents = "none";
    setTimeout(
      function () {
        this.mis.style.pointerEvents = "auto";
      }.bind(this),
      500
    );
  }

  reset() {
    this.kiss2.style.zIndex = "-1";
    this.kiss1.style.zIndex = "-2";
    this.kiss2.play();
    this.lips.style.pointerEvents = "none";
    this.mis.style.pointerEvents = "auto";
  }
}

export { Mistletoe };
