import gsap from "gsap";

let score = 0;
let scorehtml = document.querySelector(".fscorejs");
let player = document.querySelector(".player");
let currentX;

class Feast {
  constructor(game) {
    this.feastPage = document.querySelector(".feast");
    this.intro = document.querySelector(".fintro");
    this.ok = document.querySelector(".fok");
    this.done = document.querySelector(".done");
    this.over = document.querySelector(".over");

    this.start = game.start;
    this.animate;
    this.inter;
    this.active = false;
    this.activee = false;

    this.initialX;
    this.xOffset = 0;

    this.foods = [];
    this.interval = 2100;
    this.speed1 = 3;
    this.speed2 = 7.5;

    this.ok.addEventListener(
      "click",
      function (e) {
        this.foodFall();

        this.inter = setInterval(
          function () {
            this.foodFall();
          }.bind(this),
          this.interval
        );
      }.bind(this)
    );

    this.feastPage.addEventListener(
      "touchstart",
      this.dragStart.bind(this),
      false
    );
    this.feastPage.addEventListener("touchend", this.dragEnd.bind(this), false);
    this.feastPage.addEventListener("touchmove", this.drag.bind(this), false);

    this.feastPage.addEventListener(
      "mousedown",
      this.dragStart.bind(this),
      false
    );
    this.feastPage.addEventListener("mouseup", this.dragEnd.bind(this), false);
    this.feastPage.addEventListener("mousemove", this.drag.bind(this), false);
  }

  dragStart(e) {
    if (e.type === "touchstart") {
      this.initialX = e.touches[0].clientX - this.xOffset;
    } else {
      this.initialX = e.clientX - this.xOffset;
    }

    if (e.target === player) {
      this.active = true;
    }
  }

  dragEnd(e) {
    this.initialX = currentX;

    this.active = false;
  }

  drag(e) {
    if (this.active) {
      e.preventDefault();

      if (e.type === "touchmove") {
        currentX = e.touches[0].clientX - this.initialX;
      } else {
        currentX = e.clientX - this.initialX;
      }

      this.xOffset = currentX;

      this.setTranslate(currentX, 0, player);
    }
  }

  setTranslate(xPos, yPos, el) {
    el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
  }

  foodFall() {
    for (let i = 0; i < 4; i++) {
      var im = new Image();
      im.className = "food";
      im.style.position = "absolute";
      im.style.width = "10vw";
      im.src = `./feast/f${Math.floor(this.random(1, 15))}.png`;
      this.foods.push(im);
      gsap.set(im, {
        x: this.random(
          im.width,
          window.innerWidth -
            (window.innerWidth * gsap.getProperty(im, "width")) / 100
        ),
        y: this.random(-window.innerHeight - 200, -200),
      });
      this.feastPage.appendChild(im);
      console.log(im.height);
      this.anim(im);
    }
  }

  anim(elm) {
    let hit = false;
    this.animate = gsap.to(elm, {
      y: window.innerHeight + elm.width,
      duration: this.random(this.speed1, this.speed2),
      onComplete: function () {
        elm.remove();
      },
      onUpdate: function () {
        let fw = elm.style.width;
        let pw = gsap.getProperty(".player", "width");
        let ph = gsap.getProperty(".player", "height");
        let wh = window.innerHeight;
        let fx1 = gsap.getProperty(elm, "x");
        let fx2 = gsap.getProperty(elm, "x") + fw;
        let px1 = currentX + pw / 3;
        let px2 = currentX + pw - pw / 3;
        let fy = gsap.getProperty(elm, "y");

        const overlapX =
          (fx1 <= px2 && fx1 >= px1) || (fx2 <= px2 && fx2 >= px1);
        const overlapY = fy > wh - ph * 0.8 && fy < wh - ph * 0.7;

        if (!hit && overlapY && overlapX) {
          if (
            elm.getAttribute("src") === "./feast/f11.png" ||
            elm.getAttribute("src") === "./feast/f12.png" ||
            elm.getAttribute("src") === "./feast/f13.png" ||
            elm.getAttribute("src") === "./feast/f14.png" ||
            elm.getAttribute("src") === "./feast/f15.png"
          ) {
            score = score - 1;
            scorehtml.innerHTML = score;
            elm.remove();
            hit = true;
            switch (score) {
              case 10:
                player.src = "./feast/c2.png";
                break;
              case 5:
                player.src = "./feast/c1.png";
                break;
              case 0:
                player.src = "./feast/c0.png";
                break;
              case -3:
                // this.oversound.play();
                player.src = "./feast/c-1.png";
                clearInterval(this.inter);
                for (const food of this.foods) {
                  gsap.killTweensOf(food);
                }
                this.over.style.display = "block";
            }
          } else {
            score = score + 1;
            scorehtml.innerHTML = score;
            elm.remove();
            hit = true;
            switch (score) {
              case 0:
                player.src = "./feast/c0.png";
                break;
              case 5:
                player.src = "./feast/c1.png";
                break;
              case 10:
                player.src = "./feast/c2.png";
                break;
              case 15:
                player.src = "./feast/c3.png";
                break;
              case 20:
                // this.congratsound.play();
                player.src = "./feast/c4.png";
                clearInterval(this.inter);
                for (const food of this.foods) {
                  gsap.killTweensOf(food);
                }
                this.done.style.display = "block";
            }
          }
        }
      }.bind(this),
    });
  }

  random(min, max) {
    return min + Math.random() * (max - min);
  }

  reset() {
    score = 0;
    scorehtml.innerHTML = score;
    this.done.style.display = "none";
    this.over.style.display = "none";
    player.src = "./feast/c0.png";

    for (const food of this.foods) {
      gsap.killTweensOf(food);
      gsap.set(food, {
        x: 100000,
      });
      food.remove();
    }
    this.foods = [];
    clearInterval(this.inter);
  }
}

export { Feast };
