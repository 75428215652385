import gsap from "gsap";

class Santa {
  constructor(game) {
    this.santaPage = document.querySelector(".santa");
    this.wall = document.querySelector(".wall");
    this.santa1 = document.querySelector(".santa1");
    this.santa2 = document.querySelector(".santa2");
    this.santa3 = document.querySelector(".santa3");
    // this.santasound = new Audio("./santa/santa.mp3");
    this.active = false;
    this.hadPlayed = false;

    this.currentX;
    this.initialX;
    this.xOffset = 0;

    // this.ok.addEventListener("click", function (e) {
    //   alert("w");
    // });
    this.santaPage.addEventListener(
      "mousedown",
      this.dragStart.bind(this),
      false
    );
    this.santaPage.addEventListener("mouseup", this.dragEnd.bind(this), false);
    this.santaPage.addEventListener("mousemove", this.drag.bind(this), false);

    this.santaPage.addEventListener(
      "touchstart",
      this.dragStart.bind(this),
      false
    );
    this.santaPage.addEventListener("touchend", this.dragEnd.bind(this), false);
    this.santaPage.addEventListener("touchmove", this.drag.bind(this), false);
    //   }.bind(this)
    // );
  }

  dragStart(e) {
    if (e.type === "touchstart") {
      this.initialX = e.touches[0].clientX - this.xOffset;
    } else {
      this.initialX = e.clientX - this.xOffset;
    }

    if (e.target === this.wall) {
      this.active = true;
    }
  }

  dragEnd(e) {
    this.initialX = this.currentX;
    this.active = false;
  }

  drag(e) {
    if (this.active) {
      if (e.type === "touchmove") {
        this.currentX = e.touches[0].clientX - this.initialX;
      } else {
        e.preventDefault();
        this.currentX = e.clientX - this.initialX;
      }

      this.xOffset = this.currentX;

      if (
        this.currentX >= 0 &&
        this.currentX < gsap.getProperty(this.santa1, "width") / 2 + 60
      ) {
        this.santa1.style.display = "block";
      } else if (
        this.currentX >= gsap.getProperty(this.santa1, "width") / 2 + 60 &&
        this.currentX < 280
      ) {
        this.santa1.style.opacity = 0;
        this.santa2.style.display = "block";
      } else if (this.currentX >= 280) {
        // if (!this.hadPlayed) {
        //   this.santasound.play();
        // }
        // this.hadPlayed = true;

        this.santa2.style.opacity = "0";
        this.santa3.style.display = "block";
        gsap.to(this.santa3, {
          x: "-100%",
          y: -120,
          scale: 0.5,
          duration: 0.5,
          ease: "linear",
        });
      }

      this.setTranslate(this.currentX, this.wall);
    }
  }

  setTranslate(xPos, el) {
    if (xPos > 300) {
      return;
    } else if (xPos < 0) {
      xPos = 0;
    }

    el.style.transform = "translate3d(" + xPos + "px, " + "0" + "px, 0)";
  }

  reset() {
    // this.wall.style.left = 0;
    this.wall.style.transform = "translate3d(" + 0 + "px, " + "0" + "px, 0)";
    // this.setTranslate(0, this.wall);
    this.currentX = 0;
    this.hadPlayed = false;
    this.initialX = 0;
    this.xOffset = 0;
    this.santa1.style.opacity = 1;
    this.santa2.style.opacity = 1;
    this.santa2.style.display = "none";
    this.santa3.style.display = "none";
    gsap.to(this.santa3, { x: "-10%", y: 0, scale: 1, duration: 0 });
  }
}
export { Santa };
