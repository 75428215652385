import * as Matter from "matter-js";
import gsap from "gsap";

class Snowman {
  constructor(main) {
    this.snowpage = document.querySelector(".snowman");
    this.back = document.querySelector(".back");
    this.snowText = document.querySelector(".snowText");
    this.background = document.querySelector(".backc");

    //////////device movement access//////
    // window.addEventListener("click", this.onClick.bind(this));
    this.beta;
    this.gamma;

    this.motionAccess();
    this.orientAccess();

    ///////////matterjs////////////////
    this.restart();
  }

  onShake() {
    let bodies = this.World.allBodies(this.engine.world);
    bodies.forEach((body) => {
      if (!body.isStatiic) {
        let force = 0.05 * body.mass;

        Matter.Body.applyForce(body, body.position, {
          x:
            force +
            Matter.Common.random() * force * Matter.Common.choose([1, -1]),
          y: -force + Matter.Common.random() * -force,
        });
      }
    });
  }

  onOrient(e) {
    this.beta = e.beta;
    this.gamma = e.gamma;

    if (this.beta > 10) {
      this.engine.world.gravity.y = 0.025;
    } else if (this.beta < -10) {
      this.engine.world.gravity.y = -0.025;
    }

    if (this.gamma > 20) {
      this.engine.world.gravity.x = 0.025;
    } else if (this.gamma < -20) {
      this.engine.world.gravity.x = -0.025;
    }
  }

  motionAccess() {
    if (typeof DeviceMotionEvent.requestPermission === "function") {
      DeviceMotionEvent.requestPermission()
        .then((state) => {
          if (state === "granted") {
            const Shake = require("shake.js");

            let shakeEvent = new Shake({
              threshold: 10,
              timeout: 1000,
            });
            shakeEvent.start();
            console.log("f");
            window.addEventListener("shake", this.onShake.bind(this));
          } else {
            alert("Need access to device motion for full experience.");
          }
        })
        .catch();
    } else {
      const Shake = require("shake.js");

      let shakeEvent = new Shake({
        threshold: 10,
        timeout: 1000,
      });
      shakeEvent.start();
      window.addEventListener("shake", this.onShake.bind(this));

      // window.addEventListener("dblclick", this.onShake.bind(this));
    }
  }

  orientAccess() {
    if (typeof DeviceOrientationEvent.requestPermission === "function") {
      DeviceOrientationEvent.requestPermission()
        .then((permissionState) => {
          if (permissionState === "granted") {
            window.addEventListener(
              "deviceorientation",
              this.onOrient.bind(this)
            );
          } else {
            alert("Need access to device orientation for full experience.");
          }
        })
        .catch();
    } else {
      window.addEventListener("deviceorientation", this.onOrient.bind(this));
    }
  }

  restart() {
    this.Engine = Matter.Engine;
    this.Render = Matter.Render;
    this.Runner = Matter.Runner;
    this.Bodies = Matter.Bodies;
    this.World = Matter.Composite;

    this.engine = this.Engine.create({});

    this.engine.world.gravity.y = 0.01;
    this.engine.world.gravity.x = 0;

    const canvas = this.snowpage;

    this.render = this.Render.create({
      element: canvas,
      engine: this.engine,
      options: {
        wireframes: false,
        background: "transparent",
      },
    });
    this.render.canvas.width = window.innerWidth;
    this.render.canvas.height = window.innerHeight;

    let w = window.innerWidth;
    let h = window.innerHeight;

    const ceiling = this.Bodies.rectangle(w / 2, -250, w, 500, {
      isStatic: true,
      render: { fillStyle: "transparent" },
    });
    const ground = this.Bodies.rectangle(w / 2, h + 250, w, 500, {
      isStatic: true,
      render: { fillStyle: "transparent" },
    });
    const left = this.Bodies.rectangle(-250, h / 2, 500, h, {
      isStatic: true,
      render: { fillStyle: "transparent" },
    });
    const right = this.Bodies.rectangle(w + 250, h / 2, 500, h, {
      isStatic: true,
      render: { fillStyle: "transparent" },
    });

    this.World.add(this.engine.world, [ground, ceiling, left, right]);

    Array.from(Array(750), (_, i) => {
      let options = {
        density: 0.001,
        friction: 0,
        restitution: 1,

        collisionFilter: {
          group: -1,
        },
        label: "snow",
        render: {
          fillStyle: "white",
          lineWidth: 0,
          opacity: Matter.Common.random(0.5, 1.3),
        },
      };

      this.body = this.Bodies.circle(
        w * Matter.Common.random(),
        h * Matter.Common.random(),
        Matter.Common.random(0.5, 3),
        options
      );
      this.World.add(this.engine.world, [this.body]);
    });

    Array.from(Array(10), (_, i) => {
      let imageSize = Matter.Common.random(0.3, 0.5);

      let options = {
        density: 0.001,
        friction: 0,
        restitution: 1,

        collisionFilter: {
          group: -1,
        },
        label: "snow",
        render: {
          sprite: {
            texture: "./snow/snowflake.png",
            xScale: imageSize,
            yScale: imageSize,
          },
        },
      };

      this.bodyy = this.Bodies.circle(
        w * Matter.Common.random(),
        h * Matter.Common.random(),
        Matter.Common.random(1, 2),
        options
      );
      this.World.add(this.engine.world, [this.bodyy]);
    });

    this.Render.run(this.render);
    this.runner = this.Runner.create();
    this.Runner.run(this.runner, this.engine);
  }

  reset() {
    window.removeEventListener("shake", this.onShake.bind(this));
    window.removeEventListener("dblclick", this.onShake.bind(this));
    window.removeEventListener("deviceorientation", this.onOrient.bind(this));
    this.World.clear(this.engine.world);
    this.Engine.clear(this.engine);
    this.Render.stop(this.render);
    this.Runner.stop(this.runner);
    this.render.canvas.remove();
    this.render.canvas = null;
    this.render.context = null;
    this.render.textures = {};
  }
}

export { Snowman };
